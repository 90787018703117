<template>
  <div class="main-content">
    <h3 class="marginBottom20">车辆列表</h3>
    <div>
      <div class="flex flex-wrap align-items marginBottom20">
        <!-- <div class="flex align-items marginRight10 marginBottom10">
          <div class="width250">
            <el-input
              v-model="searchData.regionName"
              placeholder="输入区域名称"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div> -->

        <div class="flex align-items marginRight10 marginBottom10">
          <div class="width250">
            <el-select
              class="width250"
              v-model="searchData.regionName"
              clearable
              filterable="true"
              @change="handleSearch"
              placeholder="请选择区域名称"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="flex align-items marginRight10 marginBottom10">
          <div class="width250">
            <el-select
              class="width250"
              v-model="searchData.name"
              clearable
              filterable="true"
              @change="handleSearch"
              placeholder="请选择车型名称"
            >
              <el-option
                v-for="item in cartList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- <div class="flex align-items marginRight10 marginBottom10">
          <div class="width250">
            <el-input
              v-model="searchData.name"
              placeholder="输入车型名称"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="10"
            ></el-input>
          </div>
        </div> -->
        <!-- <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">销售合伙人类型 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.partnerTypeId"
                  clearable
                  @change="handleSearch"
                  placeholder="销售合伙人类型"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
          </div> -->

        <div class="flex align-items marginRight10 marginBottom10">
          <div class="width250">
            <el-input
              v-model="searchData.code"
              placeholder="输入车辆编号"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="50"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <div class="width200">
            <el-input
              v-model="searchData.minElectricity"
              placeholder="输入最小电量范围"
              @keyup.enter.native="handleSearch"
              clearable
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur="salaryChange($event, 'minElectricity', 100)"
              type="text"
            ></el-input>
          </div>
          <div>-</div>
          <div class="width200">
            <el-input
              v-model="searchData.maxElectricity"
              placeholder="输入最大电量范围"
              @keyup.enter.native="handleSearch"
              clearable
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur="salaryChange($event, 'maxElectricity', 100)"
              type="text"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="span marginRight10">车辆状态 : </span>
          <div class="width250">
            <el-select
              class="width250"
              v-model="searchData.moveState"
              clearable
              multiple
              placeholder="请选择"
            >
              <!-- @change="handleSearch" -->
              <!-- <el-option value="" label="请选择"></el-option> -->
              <el-option :value="1" label="可用"></el-option>
              <el-option :value="2" label="被预约"></el-option>
              <el-option :value="3" label="使用中"></el-option>
              <el-option :value="4" label="用户上报故障"></el-option>
              <el-option :value="6" label="员工上报故障"></el-option>
              <!-- <el-option :value="5" label="系统自测故障"></el-option> -->
            </el-select>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="marginLeft10 marginBottom10"
            >查询</el-button
          >
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
            class="marginLeft10 marginBottom10"
            >重置</el-button
          >
        </div>

        <div
          class="flex align-items marginRight10 marginBottom10"
          v-if="userInfo.type === 1"
        >
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
            class="marginLeft10 marginBottom10"
            >添加车辆</el-button
          >
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="primary"
            @click="handleImportImage"
            class="marginLeft10 marginBottom10"
            >导出二维码图片</el-button
          >
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="primary"
            @click="handleImportImage1"
            class="marginLeft10 marginBottom10"
            >导出车辆数据</el-button
          >
        </div>

        <div
          class="flex align-items marginRight10 marginBottom10"
          v-if="userInfo.type === 1"
        >
          <el-button
            size="medium"
            style="background: #f59a23; color: #ffffff; border-color: #f59a23"
            icon="el-icon-upload2"
            @click="handleImport"
            class="marginLeft10 marginBottom10"
            >导入车辆</el-button
          >
        </div>
      </div>
    </div>
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <template slot="regionName" slot-scope="{ row }">
        <div>{{ row.regionName ? row.regionName : "-" }}</div>
      </template>
      <template slot="startState" slot-scope="{ row }">
        <div
          v-if="loadingItem.id == row.id"
          style="z-index: 999999; cursor: pointer"
        >
          <img
            class="rotate"
            style="width: 42px; height: 42px"
            src="../../../style/image/unlocking_icon.png"
            alt=""
          />
        </div>
        <div
          v-else
          style="z-index: 999999; cursor: pointer"
          @click="handleSwitchStart(row, row.id)"
        >
          <div v-if="row.startState === 1">
            <img
              style="width: 42px; height: 42px"
              src="../../../style/image/unblankingIcon.png"
              alt=""
            />
          </div>
          <div v-if="row.startState === 2">
            <img
              style="width: 42px; height: 42px"
              src="../../../style/image/closeLockIcon.png"
              alt=""
            />
          </div>
        </div>
      </template>
      <template slot="dormancyState" slot-scope="{ row }">
        <el-switch
          v-if="row.moveState !== 3"
          v-model="row.dormancyState"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitchDormancy($event, row.id)"
        ></el-switch>
        <span v-else>{{
          row.dormancyState === 1 ? "开" : row.dormancyState === 2 ? "关" : ""
        }}</span>
      </template>
      <template slot="state" slot-scope="{ row }">
        <div>
          {{ row.state === 1 ? "启用" : row.state === 2 ? "禁用" : "" }}
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          v-if="row.regionName !== '' && row.moveState == 1"
          size="mini"
          @click="handleMoveCar(row)"
          >挪车</el-button
        >
        <el-button type="text" size="mini" @click="handleRestart(row)"
          >重启</el-button
        >
        <el-button
          v-if="
            row.moveState !== 3 && row.moveState !== 4 && row.moveState !== 5
          "
          type="text"
          size="mini"
          @click="handleSwitchState(row, row.id)"
        >
          <div :style="{ color: row.state == 1 ? '#d9001b' : '' }">
            {{ row.state == 1 ? "禁用" : row.state == 2 ? "启用" : "" }}
          </div>
        </el-button>
        <el-button
          style="color: #d9001b"
          type="text"
          size="mini"
          @click="handleDelete(row)"
          v-if="userInfo.type === 1"
          >删除</el-button
        >
        <el-button type="text" size="mini" @click="handleDetails(row)"
          >详情</el-button
        >
      </template>
    </avue-crud>
    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
import {
  list,
  deleteNew,
  updateStart,
  updateDormancy,
  updateState,
  restartCar,
  moveCar,
  regionList,
  typeList,
  getStateById,
} from "@/api/car/manage";
import Import from "@/components/import";
import qs from "qs";
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    Import,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      loading: false,
      carIds: "",
      loadingItem: {},
      searchData: {
        regionName: "",
        name: "",
        code: "",
        minElectricity: "",
        maxElectricity: "",
        moveState: [],
        sort: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        // defaultSort: {
        //   prop: "code",
        //   order: "",
        // },
        // title: "车辆列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: true, // 显示多选框
        selectionWidth: 80,
        reserveSelection: true,
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        tip: false,
        column: [],
      },
      importShow: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      importAction: "/api/web/car/manage/template/import",
      importProblem: "/api/web/car/manage/template/error/export",
      importDownloadLink: "/api/web/car/manage/template/export",
      typeList: [], //区域
      cartList: [], //车型
      timer: null, //监听的定时器
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
    this.getTypeList();
    this.getCartList();
  },
  mounted() {
    if (this.userInfo.type === 1) {
      this.option.column = [
        {
          sortable: true,
          label: "车辆编号",
          prop: "code",
        },
        {
          sortable: true,
          label: "TBOX",
          prop: "tbox",
        },
        {
          label: "车型名称",
          prop: "carTypeName",
        },
        {
          label: "所属区域",
          prop: "regionName",
        },
        {
          sortable: true,
          label: "当前电量(%)",
          prop: "currentPower",
        },
        {
          sortable: true,
          label: "TBOX当前电压(V)",
          prop: "tboxCurrentVoltage",
        },
        {
          sortable: true,
          label: "车辆当前电压(V)",
          prop: "currentVoltage",
        },
        {
          sortable: true,
          label: "车辆备用电压(V)",
          prop: "reserveVoltage",
        },
        {
          label: "运行状态",
          prop: "moveState",
          formatter: function (row, column, cellValue) {
            return cellValue === "1"
              ? "可用"
              : cellValue === "2"
              ? "被预约"
              : cellValue === "3"
              ? "使用中"
              : cellValue === "4"
              ? "用户上报故障"
              : cellValue === "5"
              ? "系统自测故障"
              : cellValue === "6"
              ? "员工上报故障"
              : cellValue;
          },
        },
        {
          label: "信号状态",
          prop: "signalState",
        },
        {
          sortable: true,
          label: "数据更新时间",
          prop: "dataUpdateTime",
        },
        {
          label: "启动开关",
          prop: "startState",
          width: 120,
        },
        {
          label: "整车休眠",
          prop: "dormancyState",
        },
        {
          label: "更新时间",
          prop: "updateTime",
        },
      ];
    } else {
      this.option.column = [
        {
          sortable: true,
          label: "车辆编号",
          prop: "code",
        },
        {
          label: "车型名称",
          prop: "carTypeName",
        },
        {
          label: "所属区域",
          prop: "regionName",
        },
        {
          sortable: true,
          label: "当前电量(%)",
          prop: "currentPower",
        },
        {
          sortable: true,
          label: "TBOX当前电压(V)",
          prop: "tboxCurrentVoltage",
        },
        {
          sortable: true,
          label: "车辆当前电压(V)",
          prop: "currentVoltage",
        },
        {
          sortable: true,
          label: "车辆备用电压(V)",
          prop: "reserveVoltage",
        },
        {
          label: "运行状态",
          prop: "moveState",
          formatter: function (row, column, cellValue) {
            return cellValue === "1"
              ? "可用"
              : cellValue === "2"
              ? "被预约"
              : cellValue === "3"
              ? "使用中"
              : cellValue === "4"
              ? "用户上报故障"
              : cellValue === "5"
              ? "系统自测故障"
              : cellValue === "6"
              ? "员工上报故障"
              : cellValue;
          },
        },
        {
          label: "信号状态",
          prop: "signalState",
        },
        {
          sortable: true,
          label: "数据更新时间",
          prop: "dataUpdateTime",
        },
        {
          label: "更新时间",
          prop: "updateTime",
        },
      ];
    }
  },
  methods: {
    //获取区域
    getTypeList() {
      regionList({}).then((res) => {
        if (res.code === 200) {
          this.typeList = res.data;
        } else {
          // this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    //获取车型
    getCartList() {
      typeList({}).then((res) => {
        if (res.code === 200) {
          this.cartList = res.data;
        } else {
          // this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    //启动状态监听
    unlockMonitoring() {
      // 创建定时器
      let time = 0;
      this.timer = setInterval(() => {
        if (time++ >= 30) {
          this.loadingItem = {};
          this.onLoad();
          clearInterval(this.timer);
          this.$message.error("操作失敗");
        } else {
          // time--
          this.getCarStartState();
          console.log(time);
        }
      }, 1000);
    },
    
    //启动状态监听
    unlockMonitoring2() {
      // 创建定时器
      let time = 0;
      this.timer = setInterval(() => {
        if (time++ >= 30) {
          this.loadingItem = {};
          this.onLoad();
          clearInterval(this.timer);
          this.$message.error("操作失敗");
        } else {
          // time--
          this.getCarStartState2();
          console.log(time);
        }
      }, 1000);
    },
    async getCarStartState() {
      const { code, data } = await getStateById(this.loadingItem.id);
      if (data !== this.loadingItem.startState) {
        this.loadingItem = {};
        this.onLoad();
        clearInterval(this.timer);
        this.$message.success("操作成功");
      }
    },
    async getCarStartState2() {
      const { code, data } = await getStateById(this.loadingItem.id);
      if (data === 2) {
        this.onLoad();
        clearInterval(this.timer);
        this.$message.success("操作成功");
      }
    },
    // 启动开关
    handleSwitchStart(row, id) {
      if (this.loadingItem.id) {
        this.$message.warning("其他车辆正在操作请稍后再试~");
        return;
      }
      // this.loadingItem = row
      if (row.startState == 1) {
        this.$confirm("您的车辆使用中确认关锁吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.loadingItem = row;
          this.unlockMonitoring();
          // return
          updateStart({
            id: id,
            startState: row.startState == 1 ? 2 : 1,
            code: row.code,
          }).then((res) => {
            if (res.code === 200) {
              // this.$message.success("操作成功");
            } else {
              this.$message.error(res.msg);
            }
            // this.onLoad();
          });
        });
      } else {
        this.loadingItem = row;
        this.unlockMonitoring();
        // return
        updateStart({
          id: id,
          startState: row.startState == 1 ? 2 : 1,
          code: row.code,
        }).then((res) => {
          if (res.code === 200) {
            // this.$message.success("操作成功");
          } else {
            this.$message.error(res.msg);
          }
          // this.onLoad();
        });
      }
    },
    handleSwitchDormancy(e, id) {
      // console.log(e,id)
      updateDormancy({
        id: id,
        dormancyState: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    handleSwitchState(row, id) {
      if (row.state == 1) {
        this.$confirm("您的车辆使用中确认禁用吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          updateState({
            id: id,
            state: row.state == 1 ? 2 : 1,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.msg);
            }
            this.onLoad();
          });
        });
      } else {
        updateState({
          id: id,
          state: row.state == 1 ? 2 : 1,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.msg);
          }
          this.onLoad();
        });
      }
      // console.log(e,id)
    },
    selectionChange(list) {
      this.carIds = list.map((item) => item.id).join(",");
    },
    handleImportImage() {
      if (this.carIds) {
        const data = {
          carIds: this.carIds,
        };
        window.open(
          `/api/web/car/manage/image/export?${qs.stringify(data)}`,
          "_self"
        );
      } else {
        this.$message({
          showClose: true,
          message: "请选择车辆",
          type: "warning",
        });
      }
    },
    handleImportImage1() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      // this.showLoading = true;
      // list({
      //   page: this.page.currentPage,
      //   size: this.page.pageSize,
      //   ...this.searchData,
      //   moveState: this.searchData.moveState != '' ? this.searchData.moveState.join(",") : '',

      const data = {
        carIds: this.carIds,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
        moveState:
          this.searchData.moveState != ""
            ? this.searchData.moveState.join(",")
            : "",
      };
      console.log("区域还是平台", this.$store.state.user.userInfo.type == 1);
      if (this.$store.state.user.userInfo.type == 1) {
        window.open(
          `/api/web/car/manage/export?${qs.stringify(data)}`,
          "_self"
        );
      } else {
        window.open(
          `/api/web/car/manage/region/export?${qs.stringify(data)}`,
          "_self"
        );
      }
    },
    sortChange(val) {
      console.log("val", val);
      if (val.prop === "code") {
        if (val.order === "descending") {
          this.searchData.sort = 1;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 2;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      if (val.prop === "tbox") {
        if (val.order === "descending") {
          this.searchData.sort = 3;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 4;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      if (val.prop === "currentPower") {
        if (val.order === "descending") {
          this.searchData.sort = 5;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 6;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      if (val.prop === "tboxCurrentVoltage") {
        if (val.order === "descending") {
          this.searchData.sort = 7;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 8;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      if (val.prop === "currentVoltage") {
        if (val.order === "descending") {
          this.searchData.sort = 9;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 10;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      if (val.prop === "reserveVoltage") {
        if (val.order === "descending") {
          this.searchData.sort = 11;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 12;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      if (val.prop === "dataUpdateTime") {
        if (val.order === "descending") {
          this.searchData.sort = 13;
        }
        if (val.order === "ascending") {
          this.searchData.sort = 14;
        }
        if (val.order === null) {
          this.searchData.sort = "";
        }
      }
      console.log(this.searchData);
      this.onLoad();
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
        moveState:
          this.searchData.moveState != ""
            ? this.searchData.moveState.join(",")
            : "",
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        regionName: "",
        name: "",
        code: "",
        minElectricity: "",
        maxElectricity: "",
        moveState: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 批量导入
    handleImport() {
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      console.log("e111", e);
      this.onLoad();
    },
    // 添加车辆
    handleCreate() {
      this.$router.push({
        path: "/car/manage/create",
        query: {
          type: "create",
        },
      });
    },
    // 编辑车辆
    handleEdit(row) {
      // // console.log(row)
      this.$router.push({
        path: "/car/manage/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 车辆详情
    handleDetails(row) {
      // // console.log(row)
      this.$router.push({
        path: "/car/manage/details",
        query: {
          type: "details",
          id: row.id,
        },
      });
    },
    //挪车
    handleMoveCar(row) {
      
      this.loadingItem = row;
      moveCar({
        id: row.id,
      }).then((res) => {
        console.log(123);
        if (res.code === 200) {
          // this.$message.success("提交成功!");
          // this.onLoad();
          this.unlockMonitoring2();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //重启
    handleRestart(row) {
      restartCar({
        id: row.id,
      }).then((res) => {
        console.log(123);
        if (res.code === 200) {
          this.$message.success("重启成功!");
          this.onLoad();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    salaryChange(e, t, max) {
      if (
        Number(this.searchData.minElectricity) >
          Number(this.searchData.maxElectricity) &&
        this.searchData.maxElectricity
      ) {
        this.searchData.minElectricity = "";
      }
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped>
/deep/ .el-table__header .el-checkbox .el-checkbox__input::after {
  content: "全选";
  font-size: 14px;
  margin-left: 5px;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
  /*开始动画后无限循环，用来控制rotate*/
}
</style>